import restUtils from "core/utils/rest";
import { Adviser, AdviserPage } from "../model";


const UMAPI = "UserManagementApi";
const ADVISER = "adviser";

class AdviserStore {

  getAdvisers = async (searchTerm: string,
    pageIndex: number,
    pageSize: number,
    field: any,
    order: string,
    dateDirection: string,
    date: any) => {
    return await restUtils.call<AdviserPage>(UMAPI, ADVISER + `?searchTerm=${encodeURIComponent(searchTerm)}&pageIndex=${pageIndex}&pageSize=${pageSize}&sortBy=${field}&sortOrder=${order}&dateDirection=${dateDirection}&date=${date}`, "GET");
  };

  getAdviser = async (id: any) => {
    return await restUtils.call<Adviser>(UMAPI, ADVISER + `/${id}`, "GET");
  };

  createAdviser = async (payload: any) => {
    return await restUtils.call<any>(UMAPI, ADVISER, "POST", payload);
  };

  updateAdviser = async (id: any, payload: any) => {
    return await restUtils.call<any>(UMAPI, ADVISER + `?id=${id}`, "PUT", payload);
  };
}

const adviserStore = new AdviserStore();
export default adviserStore;
