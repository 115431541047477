import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
import Button from "../../../components/Button";
import PageWrapper from "components/PageWrapper";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { RenderObject } from "../events/tableRow";
import { UploadOnboardingFileMutation, useUploadOnboardingFileMutation } from "./api/onboarding.generated";
import Header from "components/Header";
import { CardComponent } from "components/card/Card";
function OnboardingUpload() {
  //file validation parameters
  const MAX_FILE_SIZE = 10;
  const ALLOWED_FILE_TYPES = ["text/csv"];
  const errorMessages: { [key: string]: string } = {
    ERROR_INVALID_FILE_TYPE: ALLOWED_FILE_TYPES.length > 1 ? `Invalid file type. Only ${ALLOWED_FILE_TYPES.join(", ")} are allowed.` : `Invalid file type. Only ${ALLOWED_FILE_TYPES.join(", ")} is allowed.`,
    ERROR_FILE_TOO_LARGE: `File size exceeds the limit of ${MAX_FILE_SIZE} MB.`,
    ERROR_NO_FILE_SELECTED: "No file selected. Please choose a file to upload.",
    ERROR_UNKNOWN: "Unknown error occured."
  };
  const [onboard, setOnboard] = useState<OnboardType>();
  const [jsonData, setJsonData] = useState<string>();
  const [filename, setFilename] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [uploadOnboardingFile] = useUploadOnboardingFileMutation();
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });


  enum OnboardType {
    CUSTOMER = "customer",
    MODEL = "model",
    PRODUCT = "product"
  }

  const getErrorMessage = (code: string): string => {
    return errorMessages[code] || "Unknown error occurred.";
  };

  const validatFile = (file: File): string | null => {
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      return getErrorMessage("ERROR_INVALID_FILE_TYPE");
    }
    if ((file.size / (1024 * 1024)) > MAX_FILE_SIZE) {
      return getErrorMessage("ERROR_FILE_TOO_LARGE");
    }
    return null;
  };

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const upload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setJsonData(undefined);
    setIsActive(true);
    setIsLoading(true);
    if (!event.target.files) {
      enqueueSnackbar(getErrorMessage("ERROR_NO_FILE_SELECTED"), { variant: "error" });
      setIsLoading(false);
      return;
    }
    const file = event.target.files[0];
    const validationError = validatFile(file);
    if (validationError) {
      enqueueSnackbar(validationError, { variant: "error" });
      setIsLoading(false);
      return;
    }
    try {
      const base64: any = await convertBase64(file);
      const base64Data = base64.split(",")[1];
      setFilename(file.name);
      const jsonResponse = await uploadOnboardingFile({
        input: {
          fileData: base64Data,
          fileName: file.name,
          onboardingType: onboard!.toString()
        }
      }) as { data: UploadOnboardingFileMutation };
      if (jsonResponse.data.uploadOnboardingFile.customerData) {
        setJsonData(JSON.stringify(jsonResponse.data.uploadOnboardingFile.customerData));
      }
      else if (jsonResponse.data.uploadOnboardingFile.modelData) {
        setJsonData(JSON.stringify(jsonResponse.data.uploadOnboardingFile.modelData));
      }
      else if (jsonResponse.data.uploadOnboardingFile.productData) {
        setJsonData(JSON.stringify(jsonResponse.data.uploadOnboardingFile.productData));
      }
      setIsLoading(false);
    } catch (err) {
      enqueueSnackbar(getErrorMessage("ERROR_UNKNOWN"), { variant: "error" });
      setIsLoading(false);
      setIsActive(false);
    }
  };

  return (<PageWrapper>
    <Header title="Onboarding" subtitle="Upload onboarding file" />
    <Box display="flex" justifyContent="flex-start" mt="20px">
      <FormControl sx={{ minWidth: 150, mr: 2 }} size="small">
        <InputLabel id="onboard-select">Onboard</InputLabel>
        <Select
          labelId="onboard-select"
          id="onboard"
          value={onboard ?? ""}
          label="Onboard"
          onChange={(event) => setOnboard(event.target.value as OnboardType)}
        >
          {Object.values(OnboardType).map((value) => (
            <MenuItem key={value} value={value}>
              {`${value[0].toUpperCase()}${value.slice(1)}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        component="label"
        role={undefined}
        color="primary"
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        disabled={!onboard}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Upload File
        </Typography>
        <VisuallyHiddenInput
          type="file"
          accept=".csv"
          onChange={(event) => upload(event)}
        />
      </Button>

    </Box>

    {isActive ? <Box mt={"20px"}>
      <CardComponent
        title={"File loaded: " + filename}
        cardContent={!isLoading ? <Box>
          {jsonData ? <RenderObject payload={jsonData} /> : null}
        </Box> : <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "450px",
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        </Box>}
      ></CardComponent></Box> : null}
    {!onboard && <Box m="5px">Please select onboard type.</Box>}
  </PageWrapper>
  );
}

export default OnboardingUpload;
