import { Button, ButtonProps, styled } from "@mui/material";

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => {
  const colors = theme.palette;
  return {
    backgroundColor: theme.palette.mode === "light" ? colors.blueAccent[500] : colors.primaryAccent[200],
    "&:hover": {
      backgroundColor: theme.palette.mode === "light" ? colors.blueAccent[600] : colors.primaryAccent[300],
    },
    color: colors.grey[900],
    fontSize: "1rem",
  };
}) as typeof Button;

export default StyledButton;
