import { Box } from "@mui/material";
import InvestorUsersTable from "./investor-users-table";

function InvestorUsers() {
  return (
    <Box sx={{ paddingBottom: "64px" }}>
      <InvestorUsersTable />
    </Box>
  );
}

export default InvestorUsers;
