import { Box } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import IOConfigTable from "./io-config-table";

function IOConfig() {

  return (
    <PageWrapper>
      <Box sx={{ paddingBottom: "64px" }}>
        <IOConfigTable />
      </Box>
    </PageWrapper>
  );
}

export default IOConfig;
