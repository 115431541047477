import { Box, Chip, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";

import Header from "../../../components/Header";

import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { Link } from "react-router-dom";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

const Jobs = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const jobs: {
    path: string;
    title: string;
    stats: {
      name: string;
      color: string;
      value: number;
      type:
        | "default"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning";
    }[];
  }[] = [
    {
      path: "/jobs/documents",
      title: "Documents",
      stats: [
        {
          name: "Errors Today",
          type: "error",
          color: colors.redAccent[500],
          value: 5,
        },
        {
          name: "Jobs Today",
          type: "info",
          color: colors.blueAccent[500],
          value: 140,
        },
      ],
    },
    {
      path: "/jobs/data-sync",
      title: "Data Sync",
      stats: [
        {
          name: "Errors Today",
          type: "error",
          color: colors.redAccent[500],
          value: 2,
        },
        {
          name: "Jobs Today",
          type: "info",
          color: colors.blueAccent[500],
          value: 18,
        },
      ],
    },
  ];

  const renderChipIcon = (
    type:
      | "default"
      | "primary"
      | "secondary"
      | "error"
      | "info"
      | "success"
      | "warning"
  ) => {
    switch (type) {
      case "error":
        return <ErrorOutlineOutlinedIcon />;
      case "success":
        return <CheckCircleOutlineOutlinedIcon />;
      default:
        return <InfoOutlinedIcon />;
    }
  };

  return (
    <Box m="20px">
      <Header title="Jobs" subtitle="Document generation, data syncing, etc." />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)",
          gridAutoRows: "100px",
          gap: "20px",
        }}
      >
        {jobs.map((job) => (
          <Box
            sx={{
              backgroundColor: colors.cardBackground,
              border: `1px solid ${colors.cardBorderColor}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "1rem",
            }}
          >
            <Box
              sx={{
                width: "250px",
              }}
            >
              <Typography variant="h4">{job.title}</Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(8, 1fr)",
                gridAutoRows: "100px",
                gap: "20px",
              }}
            >
              {job.stats.map((stat) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Chip
                      variant="outlined"
                      label={`${stat.value} ${stat.name}`}
                      color={stat.type}
                      icon={renderChipIcon(stat.type)}
                    />
                  </Box>
                );
              })}
            </Box>

            <Box>
              <IconButton component={Link} to={job.path}>
                <ArrowForwardOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Jobs;
