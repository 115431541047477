import React, { forwardRef } from "react";

import {
  TableCell,
  TableRow
} from "@mui/material";

import { useNavigate } from "react-router";
import MenuComponent, { MenuItem } from "components/MenuComponent";
import { Investor } from "../../model";

interface TableRowProps {
    row: Investor;
}

const InvestorUsersRow = forwardRef<HTMLTableRowElement, TableRowProps>((props) => {
  const navigate = useNavigate();
  const row = props.row;

  const options: MenuItem[] = [
    {
      label: "Edit",
      onClick: () => navigate("/investor-users/add-edit/" + row.id)
    }
  ];

  return (
    <React.Fragment>
      <TableRow
        sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>{row?.first_name}</TableCell>
        <TableCell>{row?.username}</TableCell>
        <TableCell>{row?.email}</TableCell>
        <TableCell>{row?.phone_number}</TableCell>
        <TableCell>{row?.created_at}</TableCell>
        <TableCell>
          <MenuComponent options={options} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});

export default InvestorUsersRow;
