import {
  Box, CircularProgress,
  Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, useTheme,
} from "@mui/material";

import Header from "../../../components/Header";
import { useGetStateMachinesQuery } from "./api/stateMachines.generated";

import Row from "./stateMachineRow";

const StateMachineList = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const { data, isLoading } = useGetStateMachinesQuery();
  const stateMachineData = data?.getStateMachines?.items;

  console.log(stateMachineData);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="State Machines" subtitle="Manage and view state machine workflows" />
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: `1px solid ${colors.cardBorderColor}`,
            borderRadius: "2px",
          },
          "& .name-column--cell": {
            color: colors.grey?.[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey?.[100]}`,
          },
          "& .MuiDataGrid-paper": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
        }}
      >
        <TableContainer component={Paper}>
          <Table size={"small"} aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>ARN</TableCell>
                <TableCell>Created</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={5} >
                    <Box sx={{ minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {stateMachineData?.map((row) => (
                <Row key={row?.id} row={row!} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
export default StateMachineList;
