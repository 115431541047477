import { Box } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import TenantTable from "./intelliflo-tenant-table";

function Integrations() {

  return (
    <PageWrapper>
      <Box sx={{ paddingBottom: "64px" }}>
        <TenantTable />
      </Box>
    </PageWrapper>
  );
}

export default Integrations;
