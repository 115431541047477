import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { useAuth } from "../../../app/authHooks";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";

const initialValues = {
  username: "",
  password: "",
  rememberMe: "",
};

const loginSchema = yup.object().shape({
  username: yup.string().required("Username is required."),
  password: yup.string().required("Password is required."),
  rememberMe: yup.boolean(),
});

const LoginForm = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = async (values: any) => {
    setIsLoading(true);
    const signInResult = await auth.signIn(values.username, values.password);
    setIsLoading(false);
    if (signInResult.success) {
      if (signInResult?.path) {
        navigate({ pathname: signInResult?.path });
      } else {
        enqueueSnackbar(`Logged In but no further action implemented ${signInResult}`, { variant: "info" });
      }
    } else {
      enqueueSnackbar(signInResult.message, { variant: "error" });
    }
  };

  return (
    <Box
      sx={{
        margin: "20px",
        width: "100%",
        maxWidth: "600px",
      }}
    >
      <Header title="HUBADMIN" subtitle="Sign in" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={loginSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  name="username"
                  error={!!touched.username && !!errors.username}
                  helperText={!!touched.username && errors.username}
                  sx={{
                    gridColumn: "span 4",
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="password"
                  label="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={!!touched.password && errors.password}
                  sx={{
                    gridColumn: "span 4",
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>

              <Box
                sx={{
                  mt: "1rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControlLabel control={<Checkbox />} label="Remember me" />
                <Typography component={Link} to="/auth/forgot-password">
                  Forgot Password?
                </Typography>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={isLoading}
                  endIcon={isLoading ? <CircularProgress size={20} /> : null}
                >
                  {isLoading ? "Signing In..." : "Sign In"}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default LoginForm;
