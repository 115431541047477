import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";

import useMediaQuery from "@mui/material/useMediaQuery";

import Header from "../../../components/Header";

import Button from "../../../components/Button";

import { useAuth } from "../../../app/authHooks";
import { useNavigate } from "react-router";

const initialValues = {
  code: "",
};

const verifySchema = yup.object().shape({
  code: yup.string().required("Verification Code is required."),
});

const VerifyForm = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 600px)");

  const handleFormSubmit = async (values: any) => {
    try {
      await auth.verifyTotp(values.code);
      navigate("/");
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <Box
      sx={{
        margin: "20px",
        width: "100%",
        maxWidth: "600px",
      }}
    >
      <Header title="HUBADMIN" subtitle="Verify" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={verifySchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Verification Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                name="code"
                error={!!touched.code && !!errors.code}
                helperText={!!touched.code && errors.code}
                sx={{
                  gridColumn: "span 4",
                }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                Sign In
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default VerifyForm;
