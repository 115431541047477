import { Box, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import { useGetAllIoConfigQuery } from "../api/io-config.generated";
import { ReactNode, useEffect, useState } from "react";
import { paginationWrapper } from "app/utils";
import Header from "components/Header";
import IOConfigRow from "./io-config-row";
import PageWrapper from "components/PageWrapper";
import { useNavigate } from "react-router";

const useGetAllIoConfig = paginationWrapper(useGetAllIoConfigQuery, "getAllIoConfig");
const limit = 1000;

const TablePlaceholder: React.FC<{ children: ReactNode }> = ({ children }) => (
  <TableRow>
    <TableCell colSpan={9} align="center">
      {children}
    </TableCell>
  </TableRow>
);


function IOConfigTable() {

  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);

  const { data, isFetching } = useGetAllIoConfig(page)({ limit: limit });
  const ioConfigList = data?.getAllIoConfig?.data?.data;

  useEffect(() => {
    if (!data) {
      return;
    }
    if (!data.getAllIoConfig.data?.LastEvaluatedKey && data.getAllIoConfig.data?.scannedCount) {
      setCount(page * limit + (data.getAllIoConfig.data?.data?.length || 0));
    }
  }, [data]);

  return (
    <>
      <PageWrapper>
        <Box m="20px">
          <Box display="flex" justifyContent="space-between">
            <Header title="IO App Config" subtitle="Manage IO App Config" />
            <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={() => navigate("/io-config/add-edit")}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Add IO App Config
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            flexGrow={1}
            m="30px 0 0 0"
            sx={{
              display: "flex",
              flexDirection: "column",
              "& .MuiDataGrid-root": {
                border: `1px solid ${colors.cardBorderColor}`,
                borderRadius: "2px",
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.cardBackground,
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.cardBackground,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: colors.cardBackground,
              },
              "& .MuiDataGrid-toolbarContainer": {
                backgroundColor: colors.cardBackground,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]}`,
              },
              "& .MuiDataGrid-paper": {
                backgroundColor: `${colors.blueAccent[700]} !important`,
              },
            }}
          >
            <TableContainer sx={{ flexGrow: 1, maxHeight: "65vh" }} component={Paper}>
              <Table stickyHeader aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography noWrap variant="h5">
                        App Id
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap variant="h5">
                        Type
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap variant="h5">
                        Provider Id
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap variant="h5">
                        Provider
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap variant="h5">
                        Action
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ overflow: "scroll" }}>
                  {isFetching && (
                    <TablePlaceholder>
                      <CircularProgress />
                    </TablePlaceholder>)
                  }
                  {!isFetching && !ioConfigList?.length && (
                    <TablePlaceholder>
                      <Typography variant="h5">No Data</Typography>
                    </TablePlaceholder>)}
                  {!isFetching && ioConfigList?.map((row, index) => (
                    <IOConfigRow row={row!} key={index} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <TablePagination
            component="div"
            count={count}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[]}
            onPageChange={(_e, page) => setPage(page)}
            backIconButtonProps={isFetching ? { disabled: true } : undefined}
            nextIconButtonProps={isFetching ? { disabled: true } : undefined}
          />
        </Box>
      </PageWrapper>
    </>
  );
}

export default IOConfigTable;
