import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MemoryIcon from "@mui/icons-material/Memory";

type ItemProps = {
  title: string;
  to: string;
  icon: React.ReactNode;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
};

const Item: React.FC<ItemProps> = ({
  title,
  to,
  icon,
  selected,
  setSelected,
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = theme.palette;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const props = { selected, setSelected };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.cardBackground} !important`,
          borderRight: `1px solid ${colors.cardBorderColor}`,
        },
        "&  .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-inner-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  HUBADMIN
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item title="Dashboard" to="/" icon={<HomeOutlinedIcon />} {...props} />
            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0px 5px 20px" }}>Data</Typography>
            <Item title="Events" to="/events" icon={<FormatListBulletedOutlinedIcon />} {...props} />
            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0px 5px 20px" }}>System</Typography>
            <Item title="Jobs" to="/jobs" icon={<WorkHistoryOutlinedIcon />} {...props} />
            <Item title="Machines" to="/state-machines" icon={<MemoryIcon />} {...props} />
            <Item title="IP Whitelists" to="/ip-set" icon={<ListAltIcon />} {...props} />
            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0px 5px 20px" }}>Integration</Typography>
            <Item title="IO App Config" to="/io-config" icon={<SettingsApplicationsIcon />} {...props} />
            <Item title="Intelliflo" to="/integrations" icon={<IntegrationInstructionsIcon />} {...props} />
            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0px 5px 20px" }}>Investor Onboarding</Typography>
            <Item title="Users" to="/investor-users" icon={<PeopleOutlinedIcon />} {...props} />
            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0px 5px 20px" }}>Adviser Onboarding</Typography>
            <Item title="Users" to="/adviser-users" icon={<AccountCircleIcon />} {...props} />
            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0px 5px 20px" }}>Portal Onboarding</Typography>
            <Item title="CSVToJSON" to="/onboarding/csv-to-json" icon={<UploadFileIcon />} {...props} />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
