import React, { useEffect, useState } from "react";
import awsgateway from "aws/aws-gateway";
import {
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Chip,
  Alert,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from "@mui/lab";
import axios from "axios";

interface ChangelogItem {
  JiraReference: string;
  Assignee: string;
  Reporter: string;
  ReleaseNotes: string;
  Date: string;
}

interface ChangelogData {
  [date: string]: {
    Bug?: ChangelogItem[];
    Task?: ChangelogItem[];
    Story?: ChangelogItem[];
  };
}

const Changelog = () => {
  const [changelogData, setChangelogData] = useState<ChangelogData>({});
  const [loading, setLoading] = useState(true);
  const [searchJira, setSearchJira] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedJira, setSelectedJira] = useState<string | null>(null);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null>(null);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(true);

  const changelogApiConfig = awsgateway.API.endpoints.find((api) => api.name === "ChangelogAPi");
  const endpoint1 = changelogApiConfig?.endpoint1;
  const endpoint2 = changelogApiConfig?.endpoint2;
  console.log("endpoints",endpoint1,endpoint2);
  const limit = 500;

  const fetchChangelog = async (searchString: string = "", resetData: boolean = false) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      let encodedLastKey = "";

      if (lastEvaluatedKey && !resetData) {  // Only use lastEvaluatedKey if not resetting data
        const lastKeyJSON = JSON.stringify(lastEvaluatedKey);
        encodedLastKey = encodeURIComponent(lastKeyJSON);
      }

      const url = `${endpoint1}&searchString=${searchString}&limit=${limit}${

        encodedLastKey ? `&lastEvaluatedKey=${encodedLastKey}` : ""

      }`;

      const response = await axios.get(url);

      const newData = response.data.changelog || {};
      const newLastEvaluatedKey = response.data.lastEvaluatedKey;

      // If resetData is true (search case), we reset the state instead of merging it
      if (resetData) {
        setChangelogData(newData);
      } else {
        const mergedData = mergeChangelogData(changelogData, newData);

        const sortedData = Object.keys(mergedData)
          .sort((a, b) => b.localeCompare(a))
          .reduce((acc, key) => {
            acc[key] = mergedData[key];
            return acc;
          }, {} as ChangelogData);

        setChangelogData(sortedData);
      }

      setLastEvaluatedKey(newLastEvaluatedKey || null);
      setIsMoreDataAvailable(!!newLastEvaluatedKey);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorMessage(
          error.response?.data?.message || "An error occurred while fetching the changelog."
        );
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  const mergeChangelogData = (prevData: ChangelogData, newData: ChangelogData) => {
    const mergedData = { ...prevData };

    for (const date in newData) {
      const newDateData = newData[date];

      // Directly append the new data to the existing data for that date
      if (mergedData[date]) {
        mergedData[date].Task = [...(mergedData[date].Task || []), ...(newDateData.Task || [])];
        mergedData[date].Bug = [...(mergedData[date].Bug || []), ...(newDateData.Bug || [])];
        mergedData[date].Story = [...(mergedData[date].Story || []), ...(newDateData.Story || [])];
      } else {
        // If no data exists for this date, simply add the new data
        mergedData[date] = {
          Task: newDateData.Task || [],
          Bug: newDateData.Bug || [],
          Story: newDateData.Story || [],
        };
      }
    }

    // Return the merged data in sorted order (descending by date)
    const sortedMergedData = Object.keys(mergedData)
      .sort((a, b) => b.localeCompare(a)) // Ensure descending date order
      .reduce((acc, key) => {
        acc[key] = mergedData[key];
        return acc;
      }, {} as ChangelogData);

    return sortedMergedData;
  };

  const fetchJiraDetails = async (jiraReference: string) => {
    setModalLoading(true);
    setErrorMessage(null);
    try {
      const response = await axios.get(
        `${endpoint2}&jiraReference=${jiraReference}`
      );
      setModalData(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorMessage(
          error.response?.data?.message || "An error occurred while fetching Jira details."
        );
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    } finally {
      setModalLoading(false);
    }
  };

  useEffect(() => {
    fetchChangelog("", true);
  }, []);


  const handleSearch = async () => {
    if (searchJira) {
      setChangelogData({}); // Clear previous data before search
      fetchChangelog(searchJira, true);  // Fetch data with search string and reset previous data
    } else {
      setErrorMessage("Please provide a valid Jira reference.");
    }
  };

  const handleBack = () => {
    setChangelogData({});
    setLastEvaluatedKey(null);
    fetchChangelog("", true);
  };

  const handleLoadMore = () => {
    if (isMoreDataAvailable) {
      fetchChangelog(searchJira);
    }
  };

  const handleJiraClick = (jiraReference: string) => {
    setSelectedJira(jiraReference);
    setModalData(null);
    setOpenModal(true);
    fetchJiraDetails(jiraReference);
  };

  const renderChangelog = (data: ChangelogData) => {
    const result = [];
    for (const date in data) {
      const tasks = data[date]?.Task || [];
      const bugs = data[date]?.Bug || [];
      const stories = data[date]?.Story || [];

      const newFeatures = [
        ...tasks.map((task: any) => ({ ...task, type: "Task" })),
        ...stories.map((story: any) => ({ ...story, type: "Story" }))
      ];

      result.push(
        <TimelineItem key={date}>
          <TimelineOppositeContent
            sx={{
              flex: 0.3,
              paddingTop: 2,
            }}
          >
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {date}
            </Typography>
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineDot />
            {(newFeatures.length > 0 || bugs.length > 0) && <TimelineConnector />}
          </TimelineSeparator>

          <TimelineContent
            sx={{
              flex: 1,
              paddingTop: 2,
            }}
          >
            {/* New Features (Tasks and Stories) */}
            {newFeatures.length > 0 && (
              <>
                <Typography variant="h6" component="span" fontWeight={"bold"}>
                  New Features
                </Typography>
                {newFeatures.map((feature: any, index: number) => (
                  <Box key={index} mb={2}>
                    <Typography>
                      <Link href="#" onClick={() => handleJiraClick(feature.JiraReference)}>
                        {feature.JiraReference}
                      </Link>{" "}
                      | {feature.type} | Assignee: {feature.Assignee} | Reporter: {feature.Reporter} | Date: {feature.Date}
                    </Typography>
                    {feature.ReleaseNotes && (
                      <Typography>
                        <strong>Release Notes:</strong> {feature.ReleaseNotes}
                      </Typography>
                    )}
                    <Chip label={feature.type} color={feature.type === "Task" ? "primary" : "default"} />
                  </Box>
                ))}
              </>
            )}

            {/* Bug Section */}
            {bugs.length > 0 && (
              <>
                <Typography variant="h6" component="span" fontWeight={"bold"}>
                  Bugs
                </Typography>
                {bugs.map((bug: any, index: number) => (
                  <Box key={index} mb={2}>
                    <Typography>
                      <Link href="#" onClick={() => handleJiraClick(bug.JiraReference)}>
                        {bug.JiraReference}
                      </Link>{" "}
                      | Assignee: {bug.Assignee} | Reporter: {bug.Reporter} | Date: {bug.Date}
                    </Typography>
                    {bug.ReleaseNotes && (
                      <Typography>
                        <strong>Release Notes:</strong> {bug.ReleaseNotes}
                      </Typography>
                    )}
                    <Chip label="Bug" color="secondary" />
                  </Box>
                ))}
              </>
            )}
          </TimelineContent>
        </TimelineItem>
      );
    }
    return result;
  };

  const renderModalContent = (data: any) => {
    return data.map((item: any, index: number) => (
      <Box key={index} mb={2}>
        <Typography variant="h6" gutterBottom>
          <strong>{item.JiraReference}</strong>
        </Typography>
        <Typography><strong>Stage:</strong> {item.Stage}</Typography>
        <Typography><strong>Date:</strong> {item.Date}</Typography>
        <Typography><strong>Assignee:</strong> {item.Assignee}</Typography>
        <Typography><strong>Reporter:</strong> {item.Reporter}</Typography>
        <Typography><strong>JIRA Type:</strong> {item.JIRAType}</Typography>
        <Typography><strong>Environment:</strong> {item.Environment}</Typography>
        <Typography><strong>Release Notes:</strong> {item.ReleaseNotes}</Typography>

        {item.BuildVersions && item.BuildVersions.length > 0 && (
          <Typography>
            <strong>Build Versions:</strong>
            <ul>
              {item.BuildVersions.map((version: string, idx: number) => (
                <li key={idx}>{version}</li>
              ))}
            </ul>
          </Typography>
        )}

        {item.RefURL && (
          <Typography>
            <strong>Ref URL:</strong>{" "}
            <Link href={item.RefURL} target="_blank" rel="noopener">
              {item.RefURL}
            </Link>
          </Typography>
        )}
      </Box>
    ));
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Changelog
      </Typography>

      <Box display="flex" mb={2} sx={{ alignItems: "center" }}>
        <TextField
          label="Search by Jira Reference"
          variant="outlined"
          value={searchJira}
          onChange={(e) => {
            setSearchJira(e.target.value);
            setErrorMessage(null);
          }}
          fullWidth
          sx={{ flex: 1 }}
        />
        <Button variant="contained" color="primary" onClick={handleSearch} sx={{ ml: 2 }}>
          Search
        </Button>
      </Box>

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      {loading ? (
        <CircularProgress />
      ) : (
        <Box>
          {searchJira && (
            <Button variant="outlined" onClick={handleBack} sx={{ mb: 2 }}>
              Go Back
            </Button>
          )}
          <Timeline sx={{ alignItems: "stretch" }}>{renderChangelog(changelogData)}</Timeline>

          {isMoreDataAvailable && (
            <Box textAlign="center" mt={2}>
              <Button variant="contained" onClick={handleLoadMore}>
                Load More
              </Button>
            </Box>
          )}
        </Box>
      )}

      <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          Jira Details - {selectedJira}
          <IconButton
            aria-label="close"
            onClick={() => setOpenModal(false)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {modalLoading ? (
            <CircularProgress />
          ) : (
            modalData && renderModalContent(modalData)
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Changelog;
