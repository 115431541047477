import "./App.css";
import React from "react";
import { SnackbarProvider } from "notistack";

import { ColorModeContext, useMode } from "./theme";

import {
  CssBaseline,
  ThemeProvider,
  CircularProgress,
  Box,
} from "@mui/material";

import Router from "./Router";
import Topbar from "./core/scenes/global/Topbar";
import Sidebar from "./core/scenes/global/Sidebar";

import auth from "./app/authHooks";


function App() {
  const { isAuthenticated, isLoading } = auth.useAuth();
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <CssBaseline />

          <div className="app">
            {isAuthenticated ? <Sidebar /> : null}
            <Box component="main" className="content">
              {isAuthenticated ? <Topbar /> : null}

              {isLoading ? (
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "450px",
                    }}
                  >
                    <CircularProgress color="secondary" />
                  </Box>
                </Box>
              ) : (
                <Router />
              )}
            </Box>
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
