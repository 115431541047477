import React, { useState } from "react";
import {
  Box,
  Button, CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as yup from "yup";

import { ActiveGroup, IpListElement } from "./types";

import IpSetRow, { isEqual } from "./IpSetRow";
import { FieldArray, Form, Formik } from "formik";
import { transformedGetIpSet, useGetIpSetQuery, useUpdateIpSetMutation } from "./api";

type Props = {
  ipSetGroup: ActiveGroup
}

yup.addMethod(yup.array, "unique", function (message: string, mapper = (a: unknown) => a) {
  return this.test("unique", message, function (list) {
    if (!list?.length) return true;
    return list.length === new Set(list.map(mapper)).size;
  });
});

const validationSchema = yup.object({
  tableData: yup.array(yup.object({
    ip: yup.string().required().test("ip-test", "IP is not valid", (value) => {
      const result = value.match(/(\d{1,3})\.(\d{1,3}).(\d{1,3})\.(\d{1,3})\/(\d{2})$/);
      if (!result) return false;
      const octet = result.slice(1, 4);
      const mask = result[5];
      return octet.every((block) => Number(block) >= 0 && Number(block) <= 255) && Boolean(mask);
    }).label("IP"),
    owner: yup.string().optional().label("Owner"),
    comment: yup.string().optional().label("Comment"),
    activeMessage: yup.string().optional().label("Active Message"),
    isActive: yup.boolean().required().label("Active"),
    //@ts-ignore
  })).unique("IP must be unique", (a: transformedGetIpSet[0]) => a.ip)
});



const IpSetTable: React.FC<Props> = ({ ipSetGroup }) => {
  const [currentlyEditing, setCurrentlyEditing] = useState<number>(-1);
  const { data: tableData, isFetching } = useGetIpSetQuery({ input: ipSetGroup });
  const [updateIpSet] = useUpdateIpSetMutation();

  if (!tableData || isFetching) {
    return <CircularProgress />;
  }

  const handleSubmit = (values: { tableData: IpListElement[] }) => {
    const editedList = values.tableData.filter((value, index) => {
      const initialValue = tableData[index];
      const edited = isEqual(value, initialValue);
      return !edited;
    });
    updateIpSet({ input: { ipList: editedList, ipSet: ipSetGroup } });
  };

  return (
    <Formik
      initialValues={{ tableData }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, validateForm }) => {
        return (
          <Form>
            <FieldArray
              name="tableData"
              render={arrayHelpers => (
                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", mb: "20px" }}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>IP</TableCell>
                          <TableCell>Owner</TableCell>
                          <TableCell>Comment</TableCell>
                          <TableCell>Active</TableCell>
                          <TableCell>Active Message</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.tableData.map((value, index) => (
                          <IpSetRow
                            key={value.id || index}
                            validateForm={validateForm}
                            index={index}
                            value={value}
                            initialValue={tableData[index]}
                            currentlyEditing={currentlyEditing}
                            setCurrentlyEditing={setCurrentlyEditing}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Button
                    disabled={currentlyEditing !== -1}
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      arrayHelpers.push({ ip: "", owner: "", comment: "", isActive: true, activeMessage: "" });
                      setCurrentlyEditing(values.tableData.length);
                    }}
                  >
                    Add IP
                  </Button>
                  <Button disabled={currentlyEditing !== -1} variant="contained" type="submit">Submit</Button>
                </Box>
              )}/>
          </Form>
        );
      }}
    </Formik>
  );
};

export default IpSetTable;
